import React, { useState } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row, Form, Modal } from 'react-bootstrap';
import signupTo from "../../Assets/images/home/signup-to.webp";
import { base_url, client_name, currency, currencycode, helmet_url, postHeader } from '../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { LazyLoadImage } from "react-lazy-load-image-component";
import facebook from '../../Assets/images/miami-imgs/social-icons/facebook.png'
import twitter from '../../Assets/images/miami-imgs/social-icons/twitter.png'
import instagram from '../../Assets/images/miami-imgs/social-icons/instagram.png'
import pintrest from '../../Assets/images/miami-imgs/social-icons/pintrest.png'


function SignupTo() {
    const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader,setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
    return (
        <>
            <section className="rcs_signup_to_section">
                <Container fluid>
                    <Row>
                        <Col lg={6} md={6} xs={12} >
                            <div className="rcs_signup_to_left_main w-100 d-flex align-items-center justify-content-center">
                                <div className="rcs_signup_to_title">
                                    <p className='mb-0' >Sign Up For Exclusive Offers </p>
                                </div>
                                <div className="rcs_signup_to_input_field">
                                    <Form className="gs_news_form w-100" onSubmit={newsletter}>
                                        <InputGroup className="mb-0">
                                            <FormControl
                                                placeholder="Email Address..."
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                type="email"
                                                value={newsletteremail}
                                                required
                                                onChange={(e) => setNewsletteremail(e.target.value)}
                                            />
                                            <Button variant="outline-secondary" type="submit" id="button-addon2">
                                            sign up
                                            </Button>
                                        </InputGroup>
                                    </Form>
                                    {/* <p>By signing up you confirm that you have read the <a href="/privacy-policy">Privacy Policy</a> and agree that your email
                                        will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p> */}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={12} className="ml-auto pr-0 p-0">
                        <div className="rcs_signup_to_left_main d-flex align-items-center">
                                <div className="rcs_signup_to_title">
                                    {/* <h2> sign up to be a <br /> {client_name.c_name} insider </h2> */}
                                    <p className='mb-0'>Follow Us On:</p>
                                </div>
                                <div className='mi_solcial_icons'>
<ul className='d-flex'>
    <li><a href='https://www.facebook.com/kaziluxury?mibextid=LQQJ4d' target='_blank'><Image src={facebook} alt="facebook image"/></a></li>
    {/* <li><a href='#' target='_blank'><Image src={twitter} alt="twitter image"/></a></li> */}
    <li><a href='https://www.instagram.com/kaziluxury/?igshid=c2thN3kwdHIzMHBu' target='_blank'><Image src={instagram} alt="instagram image"/></a></li>
    {/* <li><a href='#' target='_blank'><Image src={pintrest} alt="pintrest image"/></a></li> */}
</ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal show={loader}>
            <Loader/>
        </Modal>
        </>
    );
}


export default SignupTo;
