import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Arrowleft from '../../Assets/images/miami-imgs/arrow-left.png'
import ArrowRight from '../../Assets/images/miami-imgs/arrow-right.png'
import b1 from '../../Assets/images/miami-imgs/best-seller/b1.jpg'
import b2 from '../../Assets/images/miami-imgs/best-seller/b2.jpg'
import b3 from '../../Assets/images/miami-imgs/best-seller/b3.jpg'
import b4 from '../../Assets/images/miami-imgs/best-seller/b4.jpg'
import b5 from '../../Assets/images/miami-imgs/best-seller/b5.jpg'
import b6 from '../../Assets/images/miami-imgs/best-seller/b6.jpg'
import Slider from 'react-slick'
import { FaRegHeart } from "react-icons/fa";
import { Link } from 'react-router-dom'


const MiBestSeller = () => {
  const settingsBestSeller = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows:true,
    prevArrow:<img src={Arrowleft}/>,
    nextArrow:<img src={ArrowRight}/>,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  const bestData = [
    {
        bsImg: "https://meteor.stullercloud.com/das/79174087?obj=stones/faceted/g_Center&obj.curves=0,0.121,215.185,255.255,255&obj.curves.r=41,0.60,28.128,82.176,127.237,255.255,255&obj.curves.g=22,0.128,138.248,255.255,255&obj.curves.b=20,0.128,167.251,255.255,255&obj=metals&obj=metals&obj.recipe=white&$xlarge$" ,
        bsPrice:"$129",
        bsText:"Sterling Silver Imitation Aquamarine Ring" ,
        bsUrl:"/productdetail/sterling-silver-3-mm-round-imitation-aquamarine-youth-ring-19399-150-p",
    },
    {
        bsImg: "https://meteor.stullercloud.com/das/73068977?obj=metals&obj=stones/faceted/g_center&obj.colorRef=68&obj.curves=0,0.66,0.126,51.210,202.255,255&obj=metals&obj.recipe=white&$xlarge$" ,
        bsPrice:"$918",
        bsText: `14K White Natural Onyx Ring, Multiple material` ,
        bsUrl:"/productdetail/14k-white-natural-onyx-ring-71626-1678-p",
    },
    {
        bsImg: "https://meteor.stullercloud.com/das/2802573?$xlarge$",
        bsPrice:"$1170",
        bsText:"14K White 3.8 mm Round Bypass Ring Mounting" ,
        bsUrl:"/productdetail/14k-white-3.8-mm-round-bypass-ring-mounting-120935-100002-p",
    },
    // {
    //     bsImg:b4 ,
    //     bsPrice:"$489",
    //     bsText:"14K White Gold Solitaire Oval Shape Diamond Engagement Ring" ,
    //     bsUrl:"#",
    // },
    // {
    //     bsImg:b5 ,
    //     bsPrice:"$420",
    //     bsText:"14K White Gold Solitaire Marquise Shape Diamond Engagement Ring" ,
    //     bsUrl:"#",
    // },
    // {
    //     bsImg:b6 ,
    //     bsPrice:"$441",
    //     bsText:"14K White Gold Solitaire Pear Shape Diamond Engagement Ring" ,
    //     bsUrl:"#",
    // },
 
  ]

  return (
    <>
      <div className='mi_best_seller_section'>
<Container fluid>
    <Row>
        <Col sm={12} md={4} lg={4} className='d-flex align-items-center'>
<div className='mi_best_seller_heading'>
<h2 className='mi_heading'>Best Selling Products</h2>
<p>Discover our top picks! Shop our best selling products for quality and style. Experience customer favorites that are sure to impress.</p>
</div>
        </Col>
        <Col sm={12} md={8} lg={8}>
                   <Slider {...settingsBestSeller} className='mi_bestSeller_slider'>
                        {bestData.map((val,i)=>(
                          <div className='mi_best_slider_img_box_outer' key={i}>
                           <div className='mi_best_slider_img_box'>
                             <Link to={val?.bsUrl}><Image src={val.bsImg} className="mi_client_slider_img" alt={ "Best seller Image"}/></Link>
                             <Link to={val?.bsUrl}> <p>{val.bsText}</p></Link>
                             <p className='price'>{val.bsPrice}</p>
                             <div className='heart_iconn_outer'>
                             {/* <div className='heart_iconn'>
                             <FaRegHeart />
                             </div> */}
                             </div>
                             
                        </div>
                          </div>
                          
                        ))}
                        </Slider>
        </Col>
    </Row>
</Container>
      </div>
    </>
  )
}

export default MiBestSeller
