import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button, Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import LazyLoad from 'react-lazy-load';
import bannerImg from '../../Assets/images/miami-imgs/home-banner.jpg'
import bannerImgMobile from '../../Assets/images/miami-imgs/home-banner-mobile.jpg'


const BannerSlider = () => {
  const history = useHistory();
  const [banner,setBanner] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
 //-----------------------------------Banner
 setLoading(true)
 axios.get(base_url + `/home/slider`, {
  headers: postHeader
})
  .then(response => {
    if (response.data.status == 1) {
      setLoading(false)
      setBanner(response.data.data);
      localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  })
  .catch((error) => {
    console.log(error);
  });
  },[])
  return (
    <>
    
      {/* {banner?.map((val, index) => */}
        {/* <LazyLoad height={552} width={1910}> */}
        {/* {!loading?  */}
         <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? bannerImgMobile : bannerImg})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col sm={12} md={{span:6, offset:3}} className='m-auto'>
                  <div className={isMobile ? 'text-center' :'text-center'}>
                    <h1 data-aos="fade-up" data-aos-duration="700">Perfect Symbol Of Love</h1>
                    <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">Explore the latest Wedding collection</p>
                    <div className='d-sm-block d-md-flex justify-content-center'>
                    <Button data-aos="fade-up" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/jewelry/wedding-rings') }} variant="outline-dark">Shop Wedding Rings</Button>
                    <Button data-aos="fade-up" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/jewelry/fine-jewelry') }} variant="outline-dark">Fine jewelry</Button>
                    </div>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* :<Skeleton variant="text" animation="wave"/> */}
        {/* } */}
    {/* </LazyLoad>  */}
    {/* //  )} */}
      
    </>
  )
}

export default BannerSlider;