import React from 'react'
import ScrollToTop from "react-scroll-to-top";
const MiScrollToTop = () => {
  return (
    <div>
       <ScrollToTop smooth color="#fff" className='mi_scroll_to_top_btn' />
    </div>
  )
}

export default MiScrollToTop
