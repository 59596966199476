import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Arrowleft from '../../Assets/images/miami-imgs/arrow-left.png'
import ArrowRight from '../../Assets/images/miami-imgs/arrow-right.png'
import Slider from 'react-slick'
import { BsQuote } from "react-icons/bs";
import Rating from '@mui/material/Rating';

const MiCustomer = () => {
    const customerData = [
        {
            
           cName:"Mazal Yusupov",
           cText:"What an amazing experience!!!! Eli was so patient with my pickiness and got me exactly what I needed. He showed me so many options and made sure to make a great deal with me " ,
           cUrl:"#",
        },
        {
          
           cName:"Polly Ast",
           cText:"Hey, just wanted to give a shout-out to Eli for hooking me up with some dope jewelry! I ordered an engagement online and was stoked to see that they arrived super quickly and in perfect condition " ,
           cUrl:"#",
        },
        {
           cName:"Joseph Zarett",
           cText:"I recently had the most incredible experience purchasing a Hublot Spirit of Big Bang watch from Kazi Luxury. From start to finish, the process was seamless and enjoyable, " ,
           cUrl:"#",
        },
        {
           cName:"Paris Square",
           cText:"After many years of hard work I was in the market for my first luxury watch. A good friend recommended Kazi and I couldn’t be any happier with my new Breitling." ,
           cUrl:"#",
        },
      ]
    const settingsCustomer = {
        dots: true,
        infinite: true,
        autoplay: false,
        arrows:true,
        prevArrow:<img src={Arrowleft}/>,
        nextArrow:<img src={ArrowRight}/>,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <>
         <div className='mi_customer_section'>
            <Container fluid>
                <Row>
                <Col sm={12} md={12} lg={12}>
            <div className='custome_heading_box text-center'>
<h2 className='mi_heading'>What People Say</h2>
{/* <p className='text-center'>Create a beautiful, one-of-a-kind representation of your unique love. </p> */}
            </div>
        </Col>
                </Row>
                <Row>
                <Col sm={12} md={12} lg={12} className='p-0'>
                  <Slider {...settingsCustomer} className='mi_customer_slider'>
                        {customerData.map((val)=>(
                          <div className='mi_customer_slider_img_box_outer'>
                           <div className='mi_customer_slider_img_box'>
                             {/* <Image src={val.bsImg} className="mi_client_slider_img" alt={ "customer Image"}/> */}
                             <BsQuote  className='mi_cutomer_qutoe'/>
                             <p>{val.cText} </p>
                             <div className='mb-3'>
                                 <a href='https://www.google.com/search?sca_esv=598759438&rlz=1C1CHZN_enIN1077IN1077&q=Kazi+Luxury&ludocid=6058757864866377467&lsig=AB86z5WvmakVcnwRLd5Mf2niqcBi&sa=X&ved=2ahUKEwiM4OWS3-GDAxXGVmwGHSy_ANoQoAJ6BAgiEAo&biw=1920&bih=911&dpr=1#ip=1&lrd=0x4acb7116e15564fb:0x5415082d6b76eefb,1,,,,' target='_blank' className='Read_more'>Read More</a>
                             </div>
                             {/* <a href='#' target='_blank' className='Read_more'>Read More</a><br></br> */}
                             <Rating name="half-rating-read" defaultValue={5} precision={5} readOnly  className='mi_cutomer_rating'/>
                             <p className='name'>{val.cName}</p>
                        </div>
                          </div>
                          
                        ))}
                        </Slider>
                </Col>
                </Row>
            
            </Container>
         </div>
    </>
  )
   }

export default MiCustomer
